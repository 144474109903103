import "./App.css";
import logo from "./assets/images/Logo.png";

function App() {
	return (
		<div className="App">
			<div className="logo_container">
				<img src={logo} className="logo" alt="Incarnata" />
				<a
					href="https://store.steampowered.com/app/1582800/Incarnata_Dormant_Stories/"
					target="_blank"
				>
					<h1>
					Wishlist now!
					</h1>
				</a>
			</div>
		</div>
	);
}

export default App;
